/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import "firebaseui/dist/firebaseui.css";

let firebaseui: any = {};
// This crashes on SSR
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  firebaseui = require("firebaseui");
}

export default () => {
  useEffect(() => {
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult(
          _authResult: firebase.auth.UserCredential,
          _redirectUrl: string
        ) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
          return true;
        }
      },
      signInFlow: "redirect",
      signInSuccessUrl: "/lab",
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ]
    };

    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  return (
    <div
      className="flex content-center items-center justify-center h-screen w-full"
      style={{
        backgroundImage: "linear-gradient(to top, #37ecba 0%, #72afd3 100%)"
      }}
    >
      <div className="container mx-auto my-auto p-10 shadow-lg  rounded-lg bg-white">
        <h1 className="text-center text-3xl my-5">Sign in to PPoDSLab</h1>
        <div id="firebaseui-auth-container" />
      </div>
    </div>
  );
};
